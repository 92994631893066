/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as XLSX from 'xlsx';
import Text from '@ingka/text';
import './CsvImportDialog.css';
import { UploadFile, UploadProps } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useEffect, useState } from 'react';
import Modal, {
  ModalHeader, ModalBody, Theatre, ModalFooter,
} from '@ingka/modal';
import Button from '@ingka/button';
import fileDelete from '@ingka/ssr-icon/paths/cross';
import Select, { Option } from '@ingka/select';
import IcImage from '../../../../../assets/images/ic_csv_stack.svg';
import IcImageSmall from '../../../../../assets/images/ic_csv_stack_small.svg';
import IcImageDisabled from '../../../../../assets/images/ic_csv_stack_disabled.svg';
import { excelFileType } from '../../../../../common/constants/constant';
import { setToast } from '../../../../../store/reducers/create-proj-reducer';
import { useTranslate } from '../../../../../common/services/translationService.js';
import { useAppDispatch } from '../../../../../store/hooks';
import { isNullEmptyOrUndefined } from '../../../../../common/services/commonService.js';

const CsvImportDialog = ({
  isVisible, onCloseModal, onImport, replaceFlag,
}:{
  isVisible: boolean,
  onCloseModal: () => void,
  onImport: (
    selectedArray: any[],
    duplicateArray: any[],
    invalidArray: any[],
    name:string,
    totalCount: number,
  ) => void,
  replaceFlag:boolean,
}) => {
  const csvDialogTitle = useTranslate('csv.dialog.title');
  const csvDialogColumnSelection = useTranslate('csv.dialog.columnSelection');
  const csvDialogBtnPositive = useTranslate('csv.dialog.btnPositive');
  const csvDialogBtnNegative = useTranslate('common.text.cancel');
  const fileUploadErr = useTranslate('fileUpload.format.error');
  const PhotoTextThree = useTranslate('inst.comp.PhotoTextThree');
  // State to store table Column name
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  // State to store the values
  const [selectedColumn, setSelectedColumn] = useState<any>('0');
  const [valueArray, setValueArray] = useState<any[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const dispatch = useAppDispatch();

  const csvChangeHandler = (event: any) => {
    const { file } = event;
    const reader = new FileReader();
    reader.onload = (evt) => { // evt = on_file_select event
      const rowsArray: any[] = [];
      const valuesArray: any[] = [];
      const bstr = evt?.target?.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      /* Update state */
      data.forEach((d: any) => {
        rowsArray.push(Object.keys(d));
        valuesArray.push(Object.values(d));
      });

      // Filtered Column Names
      setTableColumns(rowsArray[0]);

      // Filtered Values
      setValueArray(valuesArray);
    };
    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    if (replaceFlag === true) {
      setTableColumns([]);
      setSelectedColumn(0);
      setValueArray([]);
      setFileList([]);
    }
  }, [replaceFlag]);

  const uploadProps: UploadProps = {
    name: 'XLSX',
    listType: 'picture',
    maxCount: 1,
    className: `upload-list-inline ${fileList.length >= 1 ? 'disableUpload' : ''}`,
    multiple: false,
    accept: '.xlsx',
    itemRender: (originNode, file) => (
      <div className="csvUploadStatusWrapper">
        <div className="csvUploadStatusLeft">
          <div className="csvIconWrapper">
            <img className="icPhoto" src={IcImageSmall} alt="csvStack" />
          </div>
          <div className="csvstatusFileWrapper">
            <span className="csvStatusFileName">{file.name}</span>
          </div>
        </div>
        <Button small type="tertiary" iconOnly ssrIcon={fileDelete} onClick={() => setFileList([])} />
      </div>
    ),
    beforeUpload: (file) => {
      const updatedFile: any = file;
      updatedFile.thumbUrl = IcImage;
      const isFileSupported = excelFileType.includes(updatedFile.type);
      if (!isFileSupported) {
        dispatch(setToast({ openToast: true, toastLabel: fileUploadErr }));
      } else {
        setFileList(
          (fileLists) => [...fileLists, updatedFile],
        );
      }
      return false;
    },
    onRemove: () => {
      setFileList([]);
    },
    fileList,
    onChange: csvChangeHandler,
  };

  const filterDuplicates = (array: any[]) => {
    const uniqueArray = array.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      [],
    );
    const count = (names: any) => names.reduce((result: any, value: any) => ({
      ...result,
      [value]: (result[value] || 0) + 1,
    }), {});
    const duplicates = (dict: any) => Object.keys(dict).filter((a) => dict[a] > 1);
    const duplicateArray = duplicates(count(array));

    return { uniqueArray, duplicateArray };
  };

  const findInvalidFormats = (array: any[]) => {
    const validFormats: any = [];
    const invalidFormats: any = [];
    const regex = /^[0123456789.-]+$/;
    array.forEach((item) => {
      if (regex.test(item) && item.length <= 8) {
        validFormats.push(item);
      } else {
        invalidFormats.push(item);
      }
    });
    return { validFormats, invalidFormats };
  };

  const appendZeros = (array: any[]) => {
    const appendedArray: any[] = [];
    array.forEach((item) => {
      appendedArray.push(item?.toString()?.replace(/\./g, '').padStart(8, '0'));
    });
    return appendedArray;
  };

  const onColumnSelect = (value: any) => {
    setSelectedColumn(value);
  };

  const onImportClick = () => {
    const selectedColumnValues = valueArray?.map((val) => val[selectedColumn]);
    const stripEmptyCells = selectedColumnValues?.filter((val) => !isNullEmptyOrUndefined(val));
    const zeroAppendedarray = appendZeros(stripEmptyCells);
    const { validFormats, invalidFormats } = findInvalidFormats(zeroAppendedarray);
    const { uniqueArray, duplicateArray } = filterDuplicates(validFormats);
    onImport(
      uniqueArray,
      duplicateArray,
      invalidFormats,
      fileList[0].name,
      stripEmptyCells.length,
    );
  };

  return (
    <div className="csvImportModalWrapper">
      <Modal
        handleCloseBtn={onCloseModal}
        visible={isVisible}
        escapable={false}
      >
        <Theatre
          aria-labelledby="importCsvModal"
          header={(
            <ModalHeader
              titleId="importCsvTitle"
              title={csvDialogTitle}
              floating={false}
            />
          )}
        >
          <ModalBody>
            <div className="photoUploadContainer">
              <Dragger {...uploadProps} disabled={fileList?.length !== 0}>
                <p className={`ant-upload-drag-icon ${fileList?.length !== 0 ? 'disableUpload' : ''}`}>
                  {fileList?.length !== 0 ? (
                    <img className="emptyDataIcon icPhoto" src={IcImageDisabled} alt="csvStackDisabled" />
                  ) : (
                    <img className="emptyDataIcon icPhoto" src={IcImage} alt="csvStack" />
                  )}
                </p>
                <p className="ant-upload-text">{PhotoTextThree}</p>
              </Dragger>
              {fileList?.length !== 0 ? (
                <div className="csvColumnSelectWrapper">
                  <Text className="csvColumnSelectText">{csvDialogColumnSelection}</Text>
                  <Select
                    id="csvColumnSelect"
                    className="csvColumnSelect"
                    value={selectedColumn}
                    defaultValue="0"
                    onChange={(e) => onColumnSelect(e.target.value)}
                    hintText=""
                  >
                    {tableColumns.map((column, index) => (
                      <Option key={`${column}${index.toString()}`} value={index.toString()} name={column} />
                    ))}
                  </Select>
                </div>
              ) : null}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btCsvImport"
              text={csvDialogBtnNegative}
              type="secondary"
              small
              onClick={onCloseModal}
            />
            <Button
              className="btCsvImport"
              text={csvDialogBtnPositive}
              type="primary"
              disabled={fileList?.length === 0}
              onClick={onImportClick}
              small
            />
          </ModalFooter>
        </Theatre>
      </Modal>
    </div>
  );
};

export default CsvImportDialog;
